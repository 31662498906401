import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  createTaskFormStore,
  taskType,
  useCreateTaskFormStore,
  walletProviders,
} from "../../../store/useCreateTaskFormStore";
import { tasksService } from "services/tasksService";
import { FormInput } from "./components/FormInput";
import { projectsService } from "services/projectsService";
import { networksService } from "services/networksService";
import { FormSwitch } from "./components/FormSwitch";

export const CreateTaskView = () => {
  const {
    title,
    description,
    bonus,
    type,
    redirectUrl,
    telegramResourceId,
    telegramRedirectUrl,
    registerWithTelegramRedirectUrl,
    verifyApiUrl,
    walletProvider,
    isFeaturedTask,
    order,
    iconUrl,
    relativeRewardPercent,
    count,
    category,
    sendNotification,

    setTitle,
    setDescription,
    setBonus,
    setCount,
    setCategory,
    setType,
    setRedirectUrl,
    setTelegramResourceId,
    setTelegramRedirectUrl,
    setRegisterWithTelegramRedirectUrl,
    setVerifyApiUrl,
    setWalletProvider,
    setIsFeaturedTask,
    setOrder,
    setRelativeRewardPercent,
    setSendNotification,
  } = useCreateTaskFormStore();

  const [, setValidationError] = useState(false);

  useEffect(() => {
    projectsService.getProjects();
    networksService.getNetworks();
  }, []);

  const handleTypeChange = (e) => {
    tasksService.clearTaskCreationStorages();
    setType(e.target.value);
  };

  const handleCategroyChange = (e) => {
    setCategory(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const isQuestionsValid = tasksService.isQuestionsValid();
    if (isQuestionsValid) {
      tasksService.createTaskSubmit();
    } else {
      setValidationError(true);
    }
  };

  const [, setPickedImage] = useState(null);
  const [, setSnapshotFile] = useState(null);

  const handleImageChange = async (
    event
  ) => {
    const file = event.target.files && event.target.files[0];
    setPickedImage(null);

    if (!file?.type?.startsWith("image")) {
      return;
    }

    if (!file) {
      setPickedImage(null);
      return;
    }

    setSnapshotFile(file);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPickedImage(String(fileReader.result));
      tasksService.uploadImage(file)
    };

    fileReader.readAsDataURL(file);
  };

  return (
    <Box p={4} pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <form onSubmit={handleSubmit}>
        <Flex justify={"space-evenly"}>
          <Box>
          <FormControl id="type" mb={4}>
              <FormLabel>
                <Flex gap={6}>
                  <Box w={120} wordBreak={"break-word"}>
                    Type
                  </Box>
                  <Select maxW={200} value={type} onChange={handleTypeChange}>
                    <option value={taskType.snapshot}>Snapshot</option>
                    <option value={taskType.telegram}>Telegram</option>
                    <option value={taskType.twitter}>Twitter</option>
                    {/*<option value={taskType.registerTelegram}>
                      Register with telegram
                    </option>*/}
                    {/*<option value={taskType.wallet}>Connect Wallet</option>*/}
                    <option value={taskType.referrals}>Invite friends</option>
                  </Select>
                </Flex>
              </FormLabel>
            </FormControl>
          <FormControl id="type" mb={4}>
              <FormLabel>
                <Flex gap={6}>
                  <Box w={120} wordBreak={"break-word"}>
                    Category
                  </Box>
                  <Select maxW={200} value={category} onChange={handleCategroyChange}>
                    <option value="">Task</option>
                    <option value="QUEST">Quest</option>
                    <option value="PARTNER_TASK">Partner Task</option>
                  </Select>
                </Flex>
              </FormLabel>
            </FormControl>
           
            <FormInput
              name="title"
              handleChange={setTitle}
              value={title}
              required
            />
            <FormInput
              name="description"
              handleChange={setDescription}
              value={description}
              required
            />
            {type === taskType.referrals &&
                <FormInput
                name="refs count"
                handleChange={setCount}
                value={count}
                type="number"
                min={0}
            />}
            <FormInput
              name="bonus"
              handleChange={setBonus}
              value={bonus}
              type="number"
              min={0}
            />
            <FormInput
              name="Order"
              handleChange={setOrder}
              value={order}
              type="number"
              min={0}
            />
            <FormInput
              name="Relative Reward Percent"
              handleChange={setRelativeRewardPercent}
              value={relativeRewardPercent}
              type="number"
              min={0}
            />
            <Box sx={{
              bgColor: "white",
              color: "black",
              cursor: "pointer",
              width: '200px',
              borderRadius: '10px',
              marginBottom: '10px'
            }}>
              <label htmlFor="choose-icon" style={{
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                width: '200px',
                height: '50px',
              }}>
              <input
                accept="image/*"
                type="file"
                id="choose-icon"
                onChange={handleImageChange}
                style={{display: 'none'}}
              />
              <p>Choose image</p>
              </label>
            </Box>
            <FormSwitch
              label={"Is Task Featured"}
              value={isFeaturedTask}
              handleChange={() => setIsFeaturedTask(!isFeaturedTask)}
            />
            <FormSwitch
              label={"Send Notification To Users ?"}
              value={sendNotification}
              handleChange={() => setSendNotification(!sendNotification)}
            />
          </Box>
          <Box>
            {(type === taskType.snapshot || type === taskType.twitter) && (
              <>
                <FormInput
                  name="Redirect Url"
                  handleChange={setRedirectUrl}
                  value={redirectUrl}
                  required
                />
              </>
            )}

            {type === taskType.telegram && (
              <>
                <FormInput
                  name="resource ID"
                  handleChange={setTelegramResourceId}
                  value={telegramResourceId}
                  required
                />
                <FormInput
                  name="Redirect url"
                  handleChange={setTelegramRedirectUrl}
                  value={telegramRedirectUrl}
                  required
                />
              </>
            )}
            {type === taskType.registerTelegram && (
              <>
                <FormInput
                  name="verifyApiUrl"
                  handleChange={setVerifyApiUrl}
                  value={verifyApiUrl}
                  required
                />
                <FormInput
                  name="Redirect Url"
                  handleChange={setRegisterWithTelegramRedirectUrl}
                  value={registerWithTelegramRedirectUrl}
                  required
                />
              </>        )}
            {type === taskType.wallet && (
              <Select maxW={200} value={walletProvider} onChange={(e) => setWalletProvider(e.target.value)}>
                {Object.values(walletProviders).map((item, i) => (
                  <option key={i} value={item}>{item}</option>
                ))}
              </Select>
            )}
            {iconUrl && (
              <div className="chooseFileModalPreview">
              <img
                className="chooseFileModalSnapshotImage"
                src={iconUrl}
                alt="snapshot"
              />
                <button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    cursor: "pointer",
                    padding: '10px',
                    borderRadius: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={() => createTaskFormStore.setState({ iconUrl: "" })}
                >
                  Delete
                </button>
            </div>
            )}
          </Box>
        </Flex>
        <Button colorScheme="teal" type="submit">
          Save
        </Button>
      </form>
    </Box>
  );
};
